import React, { useContext, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from 'AppContext';
import routes from 'routes';
import style from './Space.module.scss';
import Chat from 'components/Chat/Chat';
import { ReactComponent as People } from 'assets/people.svg';
import classnames from 'classnames/bind';
import Events from 'components/Events/Events';

const cx = classnames.bind(style);

const eventCount = 3;

const Space = () => {
  const [eventsOpen, setEventsOpen] = useState(false);
  const eventsChecked = useRef(false);
  const { location } = useContext(AppContext);

  if (!location) return <Redirect to={routes.areaFind} />;

  const toggleEvents = () => {
    if (!eventsChecked.current) {
      eventsChecked.current = true;
    }
    setEventsOpen(!eventsOpen);
  };

  return (
    <main className={style.spaceContainer}>
      <div role="button" onClick={toggleEvents} className={style.eventToggler} tabIndex={-1}>
        <div>
          {eventsOpen ? (
            <span>Back</span>
          ) : (
            <span>
              <span className={cx('eventCount', { stopBumping: eventsChecked.current })}>{eventCount}</span>
              Show events
            </span>
          )}
        </div>
      </div>
      <section className={cx('eventSection', { open: eventsOpen })}>
        <Events />
      </section>
      <section className={style.chatSection}>
        <div className={style.activePeople}>
          <h4>Active people: 2</h4>
          <People />
        </div>
        <Chat />
      </section>
    </main>
  );
};

export default Space;
