import React from 'react';
import classNames from 'classnames/bind';

import useBodyScrollLock from './useBodyScrollLock';
import { ReactComponent as DownArrow } from 'assets/down-arrow.svg';

import style from './Popup.module.scss';

const cx = classNames.bind(style);

type PopupProps = {
  visible: boolean;
  onClose?: () => void;
  children?: React.ReactNode | React.ReactNode[];
};

const Popup = ({ children, visible, onClose }: PopupProps) => {
  useBodyScrollLock(visible);

  return (
    <div className={cx('popup', { visible })}>
      <div className={style.content}>
        <div role="button" className={style.closeBtn} onClick={onClose} tabIndex={-1}>
          <DownArrow height={10} className={style.closeIcon} />
        </div>
        <div className={style.contentWrapper}>{children}</div>
      </div>
      <div className={style.backdrop} />
    </div>
  );
};

export default Popup;
