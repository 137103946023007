import { useLayoutEffect } from 'react';

const useBodyScrollLock = (active: boolean) => {
  const lockScroll = () => {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.overflow = 'hidden';
  };

  const releaseScrollLock = () => {
    document.body.style.position = '';
    document.body.style.width = 'auto';
    document.body.style.overflow = 'auto';
  };

  useLayoutEffect(() => {
    if (active) {
      lockScroll();
    } else {
      releaseScrollLock();
    }
    return () => releaseScrollLock();
  }, [active]);
};

export default useBodyScrollLock;
