import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { AppContext } from 'AppContext';
import routes from 'routes';

import style from './AreaConfirm.module.scss';
import buttonStyle from 'styles/button.module.scss';

import mapPlaceholder from 'assets/area-map-placeholder.png';
import LocationQuery from 'components/LocationQuery/LocationQuery';
import Popup from 'components/Popup/Popup';
import { ILocation } from 'types/Location';

const cx = classNames.bind({ ...style, ...buttonStyle });

const AreaConfirm = () => {
  const [modify, setModify] = useState<boolean>(false);
  const { location, setLocation } = useContext(AppContext);
  const history = useHistory();

  const setToModify = () => {
    setModify(true);
  };

  const setToConfirm = () => {
    setModify(false);
  };

  const setNewLocation = (newLocation: ILocation) => {
    setLocation(newLocation);
    setModify(false);
    // TODO: Load new static map based on the new coords
  };

  const approveAndContinue = () => {
    history.push(routes.space);
  };

  const areaMapImageStyle = {
    backgroundImage: `url(${mapPlaceholder})`
  };

  if (!location) return <Redirect to={routes.areaFind} />;

  return (
    <main className={style.areaConfirm}>
      <h1>Is this your area?</h1>
      <code>
        (TODO: display place)
        {location.latitude}, {location.longitude}
      </code>
      <section className={style.areaMap}>
        {/* TODO: Load static map with area based on location coords */}
        <div className={style.mapImage} style={areaMapImageStyle} />
      </section>
      <section className={style.actionButtons}>
        <button type="button" className={cx('button', 'secondary')} onClick={setToModify}>
          No, modify it
        </button>
        <button type="button" className={cx('button', 'primary')} onClick={approveAndContinue}>
          Yes, continue
        </button>
      </section>
      <Popup visible={modify} onClose={setToConfirm}>
        <div className={style.modifyLocationModal}>
          <h3>Modify your area</h3>
          <LocationQuery onSet={setNewLocation} />
        </div>
      </Popup>
    </main>
  );
};

export default AreaConfirm;
