import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthContext } from 'components/Auth/Auth';
import routes from 'routes';

/* eslint-disable react/jsx-props-no-spreading */
const PrivateRoute = ({ component: RouteComponent, ...rest }: RouteProps) => {
  const { currentUser } = useContext(AuthContext);
  const renderFn = (props: any) => {
    return currentUser && RouteComponent ? <RouteComponent {...props} /> : <Redirect to={routes.login} />;
  };
  return <Route {...rest} render={renderFn} />;
};

export default PrivateRoute;
