import React from 'react';
import style from './Tag.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(style);

type TagProps = {
  caption: string;
  selected?: boolean;
  onClick?: () => void;
};

const Tag = ({ caption, onClick, selected = false }: TagProps) => {
  return (
    <span role="button" tabIndex={-1} onClick={onClick} className={cx('container', { active: !!onClick, selected })}>
      {caption}
    </span>
  );
};

export default Tag;
