import React from 'react';
import style from './SocialButtons.module.scss';
import firebase from 'firebase';
import { socialLogin } from 'services/auth';

type SocialButtonsProps = {
  onError: (message: string) => void;
  isLogin?: boolean;
};

const SocialButtons = ({ onError, isLogin = false }: SocialButtonsProps) => {
  const loginWithPopup = async (provider: firebase.auth.AuthProvider) => {
    try {
      await socialLogin(provider);
    } catch (error) {
      onError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    loginWithPopup(googleProvider);
  };

  const handleFacebookLogin = async () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    loginWithPopup(facebookProvider);
  };

  const captionPrefix = isLogin ? 'Login' : 'Sign up';
  return (
    <div className={style.social}>
      <button type="button" className={style.google} onClick={handleGoogleLogin}>
        {captionPrefix} with Google
      </button>
      <button type="button" className={style.facebook} onClick={handleFacebookLogin}>
        {captionPrefix} with Facebook
      </button>
      {/* <button type="button" className={style.twitter}>
        {captionPrefix} with Twitter
      </button> */}
    </div>
  );
};

export default SocialButtons;
