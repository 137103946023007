import React, { useState } from 'react';
import { ILocation } from 'types/Location';

interface IAppContext {
  location?: ILocation;
  setLocation: React.Dispatch<React.SetStateAction<ILocation | undefined>>;
}

const defaultValue: IAppContext = {
  setLocation: () => {}
};

const AppContext = React.createContext<IAppContext>(defaultValue);

const AppContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [location, setLocation] = useState<ILocation>();
  const expose = { location, setLocation };

  return <AppContext.Provider value={expose}>{children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
