import { fireAuth, firestore } from 'appFirebase';
import { User } from 'firebase';

export const socialLogin = async (provider: firebase.auth.AuthProvider) => {
  try {
    const credentials = await fireAuth.signInWithPopup(provider);
    await firestore
      .collection('users')
      .doc(credentials.user?.uid)
      .set({});
  } catch (error) {
    throw new Error(error.message);
  }
};

export const emailLogin = async (email: string, password: string) => {
  try {
    await fireAuth.signInWithEmailAndPassword(email, password);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const emailRegistration = async (email: string, password: string, displayName: string) => {
  try {
    const credentials = await fireAuth.createUserWithEmailAndPassword(email, password);
    fireAuth.onAuthStateChanged(async (user: User | null) => {
      if (!user) return;

      await user.updateProfile({
        displayName
      });
      await firestore
        .collection('users')
        .doc(credentials.user?.uid)
        .set({});
    });
  } catch (error) {
    throw new Error(error.message);
  }
};
