import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import inputStyle from 'styles/input.module.scss';
import style from './EmojiTextField.module.scss';
import { getSelectionRange, restoreSelectionRange } from 'utils/selectionRange';
import { BaseEmoji, Picker } from 'emoji-mart';
import { ReactComponent as EmojiIcon } from 'assets/emoji.svg';
import useOnClickOutside from 'utils/useOnClickOutside';

const cx = classNames.bind({ ...style, ...inputStyle });

type EmojiTextFieldProps = {
  placeholder?: string;
  onSubmit: (text: string) => void;
};

const EmojiTextField = ({ onSubmit, placeholder }: EmojiTextFieldProps) => {
  const [emojiPicker, setEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const inputSelectionRange = useRef<Range | null>(null);

  const handleTextChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const divInput = event.target as HTMLDivElement;
    const isSubmit = event.key === 'Enter' || event.keyCode === 13;
    inputSelectionRange.current = getSelectionRange();

    if (!isSubmit || !divInput.textContent) return;

    onSubmit(divInput.textContent);
    divInput.innerHTML = '';
    event.preventDefault();
  };

  const toggleEmojiPicker = useCallback(() => {
    setEmojiPicker(!emojiPicker);
  }, [setEmojiPicker, emojiPicker]);

  useOnClickOutside<HTMLDivElement>(emojiPickerRef, toggleEmojiPicker);

  const insertEmojiAtCaret = (text: string) => {
    if (!inputRef.current) return;

    if (!inputRef.current.textContent) {
      inputRef.current.textContent += text;
      return;
    }

    const selection = window.getSelection();
    if (!inputSelectionRange.current || !selection) {
      return;
    }

    const node = document.createTextNode(text);
    const range = restoreSelectionRange(inputSelectionRange.current);

    if (range) {
      range.insertNode(node);
      selection.collapseToEnd();
    }
  };

  const setInputSelection = () => {
    inputSelectionRange.current = getSelectionRange();
  };

  const addEmoji = useCallback(
    (emoji: BaseEmoji) => {
      toggleEmojiPicker();
      inputRef.current?.focus();
      insertEmojiAtCaret(emoji.native);
    },
    [toggleEmojiPicker]
  );

  return (
    <div className={style.message}>
      <div
        onClick={setInputSelection}
        onKeyUp={setInputSelection}
        ref={inputRef}
        role="textbox"
        className={cx('input', 'messageInput')}
        onKeyPress={handleTextChange}
        contentEditable
        tabIndex={-1}
        aria-label="text input field"
        data-placeholder={placeholder}
      />
      <EmojiIcon className={style.inputEmojiPicker} onClick={toggleEmojiPicker} />
      {emojiPicker && (
        <div className={style.emojiPicker} ref={emojiPickerRef}>
          <Picker
            onSelect={addEmoji}
            darkMode={false}
            color="#0eb29a"
            showPreview={false}
            showSkinTones={false}
            title=""
            emoji=""
          />
        </div>
      )}
    </div>
  );
};

export default EmojiTextField;
