import React, { useEffect, useState } from 'react';
import PlaceLoading from 'components/PlaceLoading/PlaceLoading';
import classNames from 'classnames/bind';

import style from './AreaFind.module.scss';
import { useHistory } from 'react-router-dom';
import useLocation from './useLocation';
import routes from 'routes';
import LocationQuery from 'components/LocationQuery/LocationQuery';
import { getGroupAreaForUser, mockAreas } from './getGroupAreaForUser';
import { logAnalytics } from 'appFirebase';

const cx = classNames.bind(style);

const AreaFind = () => {
  const { loading, error, location, setLocation } = useLocation();
  const [isManual, setManual] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    logAnalytics('location_finding');
  }, []);

  useEffect(() => {
    if (location) {
      // TODO: save area to firestore
      getGroupAreaForUser(location, mockAreas as any);
      history.push(routes.areaConfirm);
    }
  }, [history, location]);

  return (
    <main className={cx('areaFind', { manual: error || isManual })}>
      {!error && <h1>Finding your area...</h1>}
      <PlaceLoading loading={loading} />
      {loading && <p className={style.setManually}>...or set your location manually</p>}

      {error && <h3>{error}</h3>}
      <LocationQuery onSet={setLocation} onActive={setManual} />
    </main>
  );
};

export default AreaFind;
