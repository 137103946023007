import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  NODE_ENV
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: 'pulzon.firebaseapp.com',
  databaseURL: 'https://pulzon.firebaseio.com',
  projectId: 'pulzon',
  storageBucket: 'pulzon.appspot.com',
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

export const logAnalytics = (event: string, args?: any) => {
  if (NODE_ENV !== 'production') return;
  firebase.analytics().logEvent(event, args || {});
};

export const firestore = firebase.firestore();

export const fireAuth = firebase.auth();
