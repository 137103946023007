import React, { useLayoutEffect, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import style from 'App.module.scss';

import Header from 'components/Header/Header';
import { AppContextProvider } from 'AppContext';
import routes from 'routes';
import { AuthProvider } from 'components/Auth/Auth';

import Space from 'components/Space/Space';
import AreaFind from 'components/AreaFind/AreaFind';
import AreaConfirm from 'components/AreaConfirm/AreaConfirm';
import UserRegistration from 'components/UserRegistration/UserRegistration';
import UserLogin from 'components/UserLogin/UserLogin';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { logAnalytics } from 'appFirebase';

const App = () => {
  useEffect(() => {
    logAnalytics('initialized');
  }, []);

  useLayoutEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVh();
    window.addEventListener('resize', setVh);

    return () => window.removeEventListener('resize', setVh);
  }, []);

  return (
    <AppContextProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className={style.app}>
            <Header />
            <div className={style.container}>
              <Switch>
                <PrivateRoute exact path={routes.areaFind} component={AreaFind} />
                <PrivateRoute path={routes.areaConfirm} component={AreaConfirm} />
                <PrivateRoute path={routes.space} component={Space} />
                <Route path={routes.registration} component={UserRegistration} />
                <Route path={routes.login} component={UserLogin} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </AppContextProvider>
  );
};

export default App;
