import React, { useState, Fragment } from 'react';
import style from './EventBox.module.scss';
import buttonStyle from 'styles/button.module.scss';
import classnames from 'classnames/bind';
import { ReactComponent as PersonAdd } from 'assets/person_add.svg';
import { ReactComponent as WorkType } from 'assets/work_type.svg';
import Tag from 'components/Tag/Tag';

const cx = classnames.bind({ ...style, ...buttonStyle });

export interface EventData {
  id: number;
  type: string;
  title: string;
  creator: string;
  date: string;
  place: string;
  tags: string[];
  description: string;
  personCount: number;
}

type EventBoxProps = {
  data: EventData;
};

const EventBox = ({ data }: EventBoxProps) => {
  const [joined, setJoined] = useState(false);
  const [personCount, setPersonCount] = useState(data.personCount);

  const handleJoin = () => {
    // TODO set event person count in DB
    const newPersonCount = joined ? personCount - 1 : personCount + 1;
    setJoined(!joined);
    setPersonCount(newPersonCount);
  };

  return (
    <article className={style.eventBoxContainer}>
      <div className={style.content}>
        <div className={style.type}>
          <WorkType />
          <Tag caption="Work" />
        </div>
        <div className={style.description}>
          <h1>{data.title}</h1>
          <h3>
            {data.creator}・{data.date}
          </h3>
          <p>Place: {data.place}</p>
          <span className={style.tags}>
            {data.tags.map((tag) => (
              <Fragment key={tag}>{tag}</Fragment>
            ))}
          </span>
          <p>{data.description}</p>
        </div>
      </div>
      <footer>
        <div className={style.personGoing}>
          <PersonAdd />
          {personCount > 0 ? <span>{personCount}</span> : <p>Join first</p>}
        </div>
        <button
          type="button"
          className={cx('button', 'joinButton', { primary: !joined, secondary: joined })}
          onClick={handleJoin}>
          {joined ? 'Decline' : 'Join'}
        </button>
      </footer>
    </article>
  );
};

export default EventBox;
