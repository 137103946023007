import isEmail from 'validator/lib/isEmail';

const validations = {
  email: {
    validation: {
      required: true,
      validate: isEmail,
      maxLength: 100
    },
    messages: {
      required: 'Email is required',
      default: 'Email is not valid'
    }
  },

  displayName: {
    validation: {
      required: true,
      maxLength: 100,
      minLength: 2
    },
    messages: {
      required: 'Name is required',
      default: 'Name is not valid'
    }
  },

  password: {
    validation: {
      required: true,
      minLength: 6,
      maxLength: 50
    },
    messages: {
      required: 'Password is required',
      default: 'Password is not valid'
    }
  }
};

export default validations;
