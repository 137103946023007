import React, { useEffect, useState, useRef } from 'react';
import { fireAuth } from 'appFirebase';
import { User } from 'firebase';

interface IAuthContext {
  currentUser: User | null;
}

export const AuthContext = React.createContext<IAuthContext>({
  currentUser: null
});

export const AuthProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const authChanged = useRef(false);

  useEffect(() => {
    fireAuth.onAuthStateChanged((user: User | null) => {
      authChanged.current = true;
      if (!user) setLoading(false);
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    if (authChanged.current) setLoading(false);
  }, [currentUser]);

  return <AuthContext.Provider value={{ currentUser }}>{!loading && children}</AuthContext.Provider>;
};
