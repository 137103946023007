import { FieldError, FieldErrors, FieldValues, ValidationOptions } from 'react-hook-form';
import { FormInputs } from 'types/FormInput';

const getValidationMessage = (inputName: string, errors: FieldErrors<FieldValues>, validations: FormInputs): string => {
  const error = errors[inputName] as FieldError;
  const { messages } = validations[inputName];

  if (!error) return '';

  const type = error.type as keyof ValidationOptions;

  return messages[type] || messages.default || '';
};

export default getValidationMessage;
