import React, { useState } from 'react';
import SearchInput, { ISearchResult } from 'components/SearchInput/SearchInput';
import { ILocation } from 'types/Location';
import { logAnalytics } from 'appFirebase';

export interface ILocationQuery extends ILocation, ISearchResult {}

const dummyResults: ILocationQuery[] = [
  {
    id: 'dummy_1',
    label: 'Dummy street 1, Budapest',
    latitude: 0,
    longitude: 0
  },
  {
    id: 'dummy_2',
    label: 'Dummy street 2, Budapest',
    latitude: 1,
    longitude: 1
  }
];

type LocationQueryProps = {
  onSet: (location: ILocation) => void;
  onActive?: (active: boolean) => void;
};

const LocationQuery = ({ onSet, onActive }: LocationQueryProps) => {
  const [query, setQuery] = useState<string>('');

  const getSelectedLocation = (id: string): ILocationQuery | undefined =>
    dummyResults.find((result) => result.id === id);

  const manualSet = ({ id }: ISearchResult) => {
    const selectedLocation = getSelectedLocation(id);

    if (!selectedLocation) return;

    const { longitude, latitude } = selectedLocation;
    onSet({
      longitude,
      latitude
    });

    logAnalytics('select_content', { content_type: 'location' });
  };

  const handleQueryChange = (value: string) => {
    // TODO: load query results based on google autocomplete API
    setQuery(value);

    if (onActive) {
      onActive(!!value.length);
    }
  };

  return (
    <SearchInput
      value={query}
      results={dummyResults}
      placeholder="Search for your location..."
      onSelect={manualSet}
      onChange={handleQueryChange}
    />
  );
};

export default LocationQuery;
