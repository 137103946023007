import React, { useContext, useState } from 'react';
import classnames from 'classnames/bind';
import { FieldValues, useForm } from 'react-hook-form';
import style from './UserRegistration.module.scss';
import buttonStyle from 'styles/button.module.scss';
import SocialButtons from 'components/SocialButtons/SocialButtons';
import FormTextField from 'components/FormTextField/FormTextField';
import getValidationMessage from 'utils/getValidationMessage';
import validations from './registrationFormValidations';
import { Link, Redirect, useHistory } from 'react-router-dom';
import routes from 'routes';
import { logAnalytics } from 'appFirebase';
import { AuthContext } from 'components/Auth/Auth';
import { emailRegistration } from 'services/auth';

const cx = classnames.bind({ ...style, ...buttonStyle });

const UserRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState<string>('');
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  if (currentUser) return <Redirect to={routes.areaFind} />;

  const handleEmailSubmit = async ({ displayName, email, password }: FieldValues) => {
    setLoading(true);
    try {
      await emailRegistration(email, password, displayName);
      logAnalytics('sign_up', { method: 'email' });
      setLoading(false);
      history.push(routes.areaFind);
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const validationMessage = (field: string) => getValidationMessage(field, errors, validations);

  return (
    <section className={style.registrationSection}>
      <h1>Sign Up for Pulzon</h1>
      <div className={style.formContainer}>
        <form className={style.form} noValidate onSubmit={handleSubmit(handleEmailSubmit)}>
          <FormTextField
            name="email"
            type="email"
            placeholder="Email"
            inputRef={register(validations.email.validation)}
            errorText={validationMessage('email')}
          />
          <FormTextField
            name="displayName"
            type="text"
            placeholder="Name"
            inputRef={register(validations.displayName.validation)}
            errorText={validationMessage('displayName')}
          />
          <FormTextField
            name="password"
            type="password"
            placeholder="Password"
            inputRef={register(validations.password.validation)}
            errorText={validationMessage('password')}
          />
          <button type="submit" className={cx('button', 'primary', 'submitButton')}>
            {loading ? 'Loading...' : 'Sign up'}
          </button>
        </form>
        <div className={style.spacer}>
          <span className={style.line} />
          <span className={style.lineText}>or</span>
          <span className={style.line} />
        </div>
        <div className={style.social}>
          <SocialButtons onError={setAuthError} />
        </div>
      </div>
      <span className={style.authError}>{authError}</span>
      <h3>
        IMPORTANT: This website is under construction and it just works with dummy data. Feel free to click around and
        give me a feedback.
      </h3>
      <span className={style.hasAccountLogin}>
        Already have an account?<Link to={routes.login}>Login</Link>
      </span>
    </section>
  );
};

export default UserRegistration;
