import React from 'react';
import style from './ChatMessage.module.scss';
import ProfileImage from 'components/ProfileImage/ProfileImage';

export interface ChatMessageItem {
  id: number;
  name: string;
  time: string;
  text: string;
  profileImg: string;
}

type ChatMessageProps = {
  message: ChatMessageItem;
};

const ChatMessage = ({ message }: ChatMessageProps) => {
  return (
    <div className={style.chatMessageContainer}>
      <ProfileImage imgUrl={message.profileImg} />
      <div className={style.messageContent}>
        <div>
          <h4>{message.name}</h4>
          <span className={style.time}>{message.time}</span>
        </div>
        <p>{message.text}</p>
      </div>
    </div>
  );
};

export default ChatMessage;
