import React, { useContext, useState } from 'react';
import { ReactComponent as PulzonText } from 'assets/pulzon-text.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as Place } from 'assets/place-icon.svg';

import style from './Header.module.scss';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import { AppContext } from 'AppContext';
import { Link, useHistory } from 'react-router-dom';
import routes from 'routes';
import classnames from 'classnames/bind';
import { fireAuth } from 'appFirebase';
import { AuthContext } from 'components/Auth/Auth';

const cx = classnames.bind(style);

const landingPageUrl = String(process.env.REACT_APP_SITE_URL);

const Header = () => {
  const { location } = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const history = useHistory();

  const toggleUserMenu = () => setUserMenuOpen(!userMenuOpen);

  const handleLogOut = async () => {
    try {
      toggleUserMenu();
      await fireAuth.signOut();
      history.push(routes.login);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header className={style.header}>
      <a className={style.logo} href={landingPageUrl}>
        <Logo className={style.logoIcon} />
        <PulzonText className={style.logoText} />
      </a>
      <div className={style.menu}>
        {currentUser && (
          <>
            {location && (
              <Link to={routes.areaFind} className={style.placeLink}>
                <Place height={40} width={30} />
                <span className={style.placeText}>Budapest, Nyugati ter</span>
              </Link>
            )}
            <div className={style.userProfile} role="button" tabIndex={-1} onClick={toggleUserMenu}>
              <ProfileImage imgUrl="https://www.fillmurray.com/g/70/85" />
            </div>
            <div className={cx('userMenu', { userMenuOpen })}>
              <span role="button" tabIndex={-1} onClick={handleLogOut}>
                Log out
              </span>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
