import React, { useContext, useState } from 'react';
import classnames from 'classnames/bind';
import style from './UserLogin.module.scss';
import buttonStyle from 'styles/button.module.scss';
import SocialButtons from 'components/SocialButtons/SocialButtons';
import FormTextField from 'components/FormTextField/FormTextField';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory } from 'react-router-dom';
import routes from 'routes';
import { logAnalytics } from 'appFirebase';
import { AuthContext } from 'components/Auth/Auth';
import { emailLogin } from 'services/auth';

const cx = classnames.bind({ ...style, ...buttonStyle });

const UserLogin = () => {
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState<string>('');
  const { register, getValues } = useForm();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  if (currentUser) return <Redirect to={routes.areaFind} />;

  const handleEmailLogin = async () => {
    const { email, password } = getValues();
    setLoading(true);
    try {
      await emailLogin(email, password);
      logAnalytics('login', { method: 'email' });
      setLoading(false);
      history.push(routes.areaFind);
    } catch (error) {
      setAuthError(error.message);
    }
  };

  return (
    <section className={style.loginSection}>
      <h1>Login</h1>
      <div className={style.formContainer}>
        <div className={style.form}>
          <FormTextField name="email" type="email" placeholder="Email" inputRef={register()} />
          <FormTextField name="password" type="password" placeholder="Password" inputRef={register()} />
          <button type="submit" className={cx('button', 'primary', 'submitButton')} onClick={handleEmailLogin}>
            {loading ? 'Loading...' : 'Login'}
          </button>
        </div>
        <div className={style.spacer}>
          <span className={style.line} />
          <span className={style.lineText}>or</span>
          <span className={style.line} />
        </div>
        <div className={style.social}>
          <SocialButtons isLogin onError={setAuthError} />
        </div>
      </div>
      <span className={style.authError}>{authError}</span>
      <h3>
        IMPORTANT: This website is under construction and it just works with dummy data. Feel free to click around and
        give me a feedback.
      </h3>
      <span className={style.noAccountSignUp}>
        Need an account?<Link to={routes.registration}>Sign up</Link>
      </span>
    </section>
  );
};

export default UserLogin;
