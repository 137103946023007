import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'AppContext';
import { logAnalytics } from 'appFirebase';

const errorMessages = {
  default: 'Well, we tried it, but your location is unavailable, please set your location manually.',
  denied: 'Oh, you blocked the automatic Geolocation, please set your location manually.',
  notSupported: 'Oh nooo, automatic Geolocation is not supported by this browser, please set your location manually.'
};

const geolocationOptions: PositionOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24 // 24 hour
};

const useLocation = () => {
  const { location, setLocation } = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const locationWatchId = useRef<number | null>(null);
  const loading = !location && !error;

  const handleErrorPosition = ({ code, PERMISSION_DENIED }: PositionError) => {
    let errorMsg: string;
    if (code === PERMISSION_DENIED) {
      errorMsg = errorMessages.denied;
    } else {
      errorMsg = errorMessages.default;
    }
    logAnalytics('location_finding_error', { error: errorMsg });
    setError(errorMsg);
  };

  const handlePosition = useCallback(
    (position: Position) => {
      logAnalytics('select_content_auto', { content_type: 'location' });
      const { longitude, latitude } = position.coords;
      setLocation({
        longitude,
        latitude
      });
    },
    [setLocation]
  );

  useEffect(() => {
    if (!navigator.geolocation) {
      setError(errorMessages.notSupported);
    }

    const watchForLocation = () => {
      locationWatchId.current = navigator.geolocation.watchPosition(
        handlePosition,
        handleErrorPosition,
        geolocationOptions
      );
    };

    const clearLocationWatch = () => {
      if (locationWatchId.current) {
        navigator.geolocation.clearWatch(locationWatchId.current);
      }
    };

    if (!location) {
      watchForLocation();
    } else {
      clearLocationWatch();
    }

    return clearLocationWatch;
  }, [handlePosition, location]);

  return { location, error, loading, setLocation };
};

export default useLocation;
