export const restoreSelectionRange = (prevRange: Range): Range | null => {
  if (!window.getSelection) return null;

  const sel = window.getSelection();

  if (sel) {
    sel.removeAllRanges();
    sel.addRange(prevRange);
    return sel.getRangeAt(0);
  }

  return null;
};

export const getSelectionRange = (): Range | null => {
  if (!window.getSelection) return null;

  const sel = window.getSelection();

  if (sel && sel.getRangeAt) {
    return sel.getRangeAt(0);
  }

  return null;
};
