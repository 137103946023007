import React from 'react';
import classNames from 'classnames/bind';
import { ReactComponent as PlaceIcon } from 'assets/place-icon.svg';

import style from './PlaceLoading.module.scss';

type PlaceLoadingProps = {
  loading: boolean;
};

const cx = classNames.bind(style);

const PlaceLoading = ({ loading }: PlaceLoadingProps) => {
  return (
    <div className={cx('placeLoading', { loading })}>
      <div className={style.placeIconContainer}>
        <PlaceIcon height="120" className={style.placeIcon} />
      </div>
      <div className={style.loaderBar}>
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

export default PlaceLoading;
