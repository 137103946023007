import React, { HTMLProps } from 'react';
import classnames from 'classnames/bind';
import style from './FormTextField.module.scss';
import inputStyle from 'styles/input.module.scss';

const cx = classnames.bind({ ...style, ...inputStyle });

type FormTextFieldProps = HTMLProps<HTMLInputElement> & {
  errorText?: string;
  inputRef?: any;
};

const FormTextField = ({ name, type, placeholder, inputRef, errorText = '' }: FormTextFieldProps) => {
  return (
    <>
      <input
        ref={inputRef}
        type={type}
        name={name}
        className={cx('input', 'formField', { hasError: errorText && errorText.length })}
        placeholder={placeholder}
      />
      <span className={style.errorText}>{errorText}</span>
    </>
  );
};

export default FormTextField;
