import React from 'react';
import classNames from 'classnames/bind';
import inputStyle from 'styles/input.module.scss';
import style from './SearchInput.module.scss';

export interface ISearchResult {
  id: string;
  label: string;
}

type SearchInputProps = {
  value: string;
  results?: ISearchResult[];
  placeholder?: string;
  onChange?: (value: string) => void;
  onSelect?: (result: ISearchResult) => void;
};

const cx = classNames.bind({ ...style, ...inputStyle });

const SearchInput = ({ results = [], placeholder, value, onSelect, onChange }: SearchInputProps) => {
  const queryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(event.target.value);
  };

  const selectItem = (item: ISearchResult) => {
    if (!onSelect) return;
    onSelect(item);
  };

  return (
    <div className={cx('container', { suggestionsOpen: value.length })}>
      <input
        className={cx('input', 'searchInput')}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={queryChange}
      />
      <div className={style.suggestions}>
        {results.map((result) => (
          <div
            role="button"
            className={style.suggestionItem}
            key={result.label}
            onClick={() => selectItem(result)}
            tabIndex={0}>
            {result.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchInput;
