import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import style from './Chat.module.scss';
import ChatMessage, { ChatMessageItem } from 'components/ChatMessage/ChatMessage';
import EmojiTextField from 'components/EmojiTextField/EmojiTextField';

const dummyMessage = {
  id: 0,
  name: 'Name Test',
  time: '14:54 PM',
  text: 'Random text from the person who wrote this message',
  profileImg: 'https://www.fillmurray.com/g/60/60'
};

// TODO: implement realtime chat
const dummyMessages: ChatMessageItem[] = [...Array(20)].map((_, i) => ({
  ...dummyMessage,
  id: i,
  time: new Date().toLocaleTimeString(undefined, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  })
}));

const Chat = () => {
  const [messages, setMessages] = useState(dummyMessages);
  const feedRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!feedRef.current) return;
      feedRef.current.scrollTop = feedRef.current.scrollHeight;
    }, 100);
  }, [feedRef, messages]);

  const handleAddMessage = useCallback(
    (text: string) => {
      const newMessage = {
        ...dummyMessage,
        id: messages.length,
        text
      };
      setMessages([...messages, newMessage]);
    },
    [setMessages, messages]
  );

  return (
    <div className={style.chatContainer}>
      <div className={style.chatFeed} ref={feedRef}>
        {messages.map((message) => (
          <div key={message.id} className={style.chatItem}>
            <ChatMessage message={message} />
          </div>
        ))}
      </div>
      <EmojiTextField onSubmit={handleAddMessage} placeholder="Message" />
    </div>
  );
};

export default Chat;
