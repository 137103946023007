import React from 'react';

import style from './ProfileImage.module.scss';

type ProfileImageProps = {
  imgUrl: string;
};

const ProfileImage = ({ imgUrl }: ProfileImageProps) => {
  return <span className={style.profileImage} style={{ backgroundImage: `url(${imgUrl})` }} />;
};

export default ProfileImage;
