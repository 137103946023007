import React, { useEffect, useState } from 'react';
import style from './Events.module.scss';
import EventBox from 'components/EventBox/EventBox';
import Tag from 'components/Tag/Tag';

// TODO: get events based on location
const dummyEvent = {
  id: 0,
  type: 'work',
  title: 'Lets chat about React hooks',
  creator: 'Rand Name',
  date: 'Today 13:00',
  place: 'Starbucks, Nyugati ter',
  tags: ['#webdevelopment', '#knowledgeshare'],
  description: 'I would like to share my experiences with React Hooks. Lets chat about it next to a cup of coffee...',
  personCount: 2
};

const dummyEvents = [...Array(10)].map((_, i) => ({
  ...dummyEvent,
  id: i
}));

const dummyTags = ['All', 'Work', 'Coffee', 'Lunch', 'Other'];

const Events = () => {
  const [events, setEvents] = useState(dummyEvents);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const filteredEvents = dummyEvents.filter((event) => event.type === filter || filter === 'all');
    setEvents(filteredEvents);
  }, [filter]);

  const handleSelectType = (tag: string) => {
    setFilter(tag.toLowerCase());
  };

  return (
    <div className={style.eventsContainer}>
      <div className={style.filterTags}>
        {dummyTags.map((tag) => (
          <div key={tag} className={style.filterTagItem}>
            <Tag caption={tag} selected={tag.toLowerCase() === filter} onClick={() => handleSelectType(tag)} />
          </div>
        ))}
      </div>
      <div className={style.eventList}>
        {!events.length && (
          <>
            <h3 className={style.emptyEvents}>
              There is no {filter !== 'all' && `"${filter}"`} event in your area... :(
            </h3>
            <h3 className={style.emptyEvents}>Create you the first one.</h3>
          </>
        )}
        {events.map((event) => (
          <div key={event.id} className={style.eventItem}>
            <EventBox data={event} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;
