import { ILocation } from 'types/Location';
import { Feature, Point, Polygon, featureCollection, point } from '@turf/helpers';
import buffer from '@turf/buffer';
import booleanContains from '@turf/boolean-contains';
import { randomPoint } from '@turf/random';
import centroid from '@turf/centroid';
import nearestPoint from '@turf/nearest-point';

const areaCircleRadiusInKm = 5;

// MOCK DATA
const tempInsidePoint = point([19.046415, 47.487816], { name: 'asd' });
const temp2InsidePoint = point([19.067987, 47.522252], { name: 'qwer' });
const areaContainsBuffer = buffer(tempInsidePoint, areaCircleRadiusInKm);
const areaContains2Buffer = buffer(temp2InsidePoint, areaCircleRadiusInKm);

const randomAreas = randomPoint(5, { bbox: [18.903799, 47.286753, 19.444875, 47.62012] }).features.map((pointF) =>
  buffer(pointF, areaCircleRadiusInKm)
);
export const mockAreas = [...randomAreas, areaContainsBuffer, areaContains2Buffer];
// MOCK DATA

const filterContainingAreas = (userPoint: Feature<Point>, areas: Feature<Polygon>[]) => {
  return areas.filter((area) => booleanContains(area, userPoint));
};

const selectNearestContainingArea = (userPoint: Feature<Point>, containingAreas: Feature<Polygon>[]) => {
  const areasCenterPoint = containingAreas.map((area: Feature<Polygon>) => centroid(area));
  const areasCenterPointCollection = featureCollection<Point>(areasCenterPoint);
  return nearestPoint(userPoint, areasCenterPointCollection);
};

const locationToPoint = (location: ILocation) => point(Object.values(location));

export const getGroupAreaForUser = (userLocation: ILocation, areas: Feature<Polygon>[]): Feature<Polygon> | null => {
  const userPoint = locationToPoint(userLocation);
  const areasContainingUserPoint = filterContainingAreas(userPoint, areas);

  if (!areasContainingUserPoint.length) return null;

  const nearestArea = selectNearestContainingArea(userPoint, areasContainingUserPoint);
  const targetArea = areasContainingUserPoint[nearestArea.properties.featureIndex];

  return targetArea;
};

export const createNewGroupArea = (userLocation: ILocation): Feature<Polygon> => {
  const userPoint = locationToPoint(userLocation);
  const newArea = buffer(userPoint, areaCircleRadiusInKm);

  return newArea as Feature<Polygon>;
};
